import { template as template_cde95d4df1084df7ab59506b8ae52b01 } from "@ember/template-compiler";
const FKControlMenuContainer = template_cde95d4df1084df7ab59506b8ae52b01(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
