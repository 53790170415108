import { template as template_c0d6a1c734314ed09f487d5c98fd4c4a } from "@ember/template-compiler";
const WelcomeHeader = template_c0d6a1c734314ed09f487d5c98fd4c4a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
