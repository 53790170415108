import { template as template_bc17052be26d4b1681882d0be96f75da } from "@ember/template-compiler";
const FKText = template_bc17052be26d4b1681882d0be96f75da(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
