import { template as template_c98a59a961e84bbf8140d93601775ba5 } from "@ember/template-compiler";
const FKLabel = template_c98a59a961e84bbf8140d93601775ba5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
